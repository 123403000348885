import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, TextField, Typography, FormControl, InputLabel, MenuItem, Select, formControlLabelClasses } from "@mui/material";
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
// import { useParams } from 'react-router-dom';

const OrderPackingPage = () => {

    // const { id } = useParams();
    // console.log(id)

    const [orderId, setOrderId] = useState('');
    const [warehouseId, setWarehouseId] = useState('');
    const [serialNumber, setSerialNumber] = useState("");
    const [selectedSerialNumbers, setSelectedSerialNumber] = useState([]);
    const [warehouseDrop, setWarehouseDrop] = useState([]);
    // const [serialNumberCall, setSerialNumberCall] = useState([]);
    const [itemData, setItemData] = useState([]);
    const [orderFiled, setOrderFiled] = useState(true);
    const [orderCanPack, setOrderCanPack] = useState(true);
    const [error, setError] = useState('');


    // console.log(selectedSerialNumbers);
    const submitInput = () => {
        console.log(serialNumber);
        const tempCopy = [...selectedSerialNumbers];
        tempCopy.push(serialNumber);

        setSelectedSerialNumber(tempCopy);
        setSerialNumber('');
        submitSingleOrder(tempCopy)
    }

    const submitSingleOrder = async (defaultSerialNumber = formControlLabelClasses) => {

        try {
            const response = await axios
                .post('/api/v3/single-order/orderItems', {
                    orderId,
                    warehouseId,
                    SerialNumber: defaultSerialNumber ? defaultSerialNumber : selectedSerialNumbers,
                })
            if (response.data.error) {
                toast.error(response.data.error);
            } else if (!response.data.error) {
                console.log(response.data.error)
                if (response.data.data.packResponse.canBePacked) {
                    setOrderCanPack(false)
                }
                else if (response.data.data.packResponse.canNotPackError || response.data.data.packResponse.canNotPackWarning) {
                    setError(response.data.data.packResponse.canNotPackError + response.data.data.packResponse.canNotPackWarning)
                    setSelectedSerialNumber([])
                }
                else if (!response.data.data.packResponse.canNotPackError && !response.data.data.packResponse.canNotPackWarning) {
                    setError("")
                }
                setItemData(response.data.data)
                setOrderFiled(false)
            }
        } catch (error) {
            toast.error(error);
        }

    }

    const confirmOrderPacking = async () => {
        try {
            const response = await axios.post('/api/v3/single-order/packOrder', {
                orderId,
                warehouseId,
                SerialNumber: selectedSerialNumbers,
            })
            if (response.data.error) {
                toast.error(response.data.error)
            } else {
                toast.success(response.data.message)
                window.location.reload();
                alert(response.data.status)
            }

        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        const getWarehouseDorps = async () => {
            await axios
                .post('/api/v3/inventory/warehouse/getAllWarehouse')
                .then((res) => {
                    if (res.data.error) {
                        console.log(res.data.message);
                    } else if (!res.data.error) {
                        setWarehouseDrop(res.data.data);
                    }
                    return res;
                })
                .catch((err) => console.log(err.message));
        };
        getWarehouseDorps();
    }, []);

    return (
        <>

            <Grid container
                spacing={0}
                mt={10}

            >
                <Grid item xs={12} alignItems="center"
                    justifyContent="center">
                    <center>
                        <Typography fontWeight="bold" fontSize="20px">ORDERS PACKING</Typography>
                        {error && <Typography fontWeight="bold" color="red" fontSize="20px">{error}</Typography>}
                    </center>


                </Grid>

                <Grid item xs={12} md={12}>
                    <Grid container spacing={2} mt={2}>
                        {/* <Grid item xs={12} md={6} >
                            <TextField id="outlined-basic" sx={{ width: "100%" }} value={orderId} onChange={e => setOrderId(e.target.value)} label="OrderId" variant="outlined" fullWidth />
                        </Grid> */}

                        <Grid item xs={12} md={6} alignItems="center">

                            <FormControl sx={{ mr: 1, ml: 1, width: '95%' }} >
                                <InputLabel id="demo-simple-select-label">Warehouse ID</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Warehouse"
                                    value={warehouseId}
                                    onChange={(e) => setWarehouseId(e.target.value)}
                                    required
                                    fullWidth
                                >
                                    {warehouseDrop?.map((war) => {
                                        return <MenuItem value={war._id}>{war.name}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>


                    </Grid>
                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12} md={6} alignItems="center">
                            {
                                orderFiled ? (
                                    <TextField id="outlined-basic" sx={{ mr: 1, ml: 1, width: "95%" }} value={orderId} onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            submitSingleOrder();
                                        }
                                    }} onChange={e => setOrderId(e.target.value)} label="Order ID" variant="outlined" fullWidth />
                                ) : (
                                    <TextField id="outlined-basic" sx={{ mr: 1, ml: 1, width: "95%" }} value={serialNumber} onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            submitInput();
                                        }
                                    }} onChange={e => setSerialNumber(e.target.value)} label="Scan Item" variant="outlined" fullWidth />
                                )
                            }
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <center>
                                <Typography mt={2}>Selected Order #{orderId}</Typography>
                            </center>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={2}>
                        <Grid item xs={12} md={6} mt={2} >
                            <center>
                                <Typography fontWeight="bold" fontSize="18px">Order Items Selected </Typography>
                            </center>
                            <Box mt={2}>
                                <center>
                                    {itemData.orderDataScanned?.map(item => (
                                        <Box display="" key={item.sku}>
                                            <Typography>{item.sku}</Typography>
                                        </Box>

                                    ))}
                                </center>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} mt={2}>
                            <center>
                                <Typography fontWeight="bold" fontSize="18px">Order Items Remaining</Typography>
                            </center>
                            <Box mt={2}>
                                <center>
                                    {itemData.orderDataRemain?.map(item => (
                                        <Box display="" key={item.sku}>
                                            <Typography>{item.sku}</Typography>
                                        </Box>
                                    ))}
                                </center>
                            </Box>
                        </Grid>
                    </Grid>

                    {orderCanPack ? (<Button variant="contained" sx={{ width: "100%", mt: 10 }} onClick={confirmOrderPacking}
                        disabled
                    >Mark Packed</Button>) : (<Button variant="contained" sx={{ width: "100%", mt: 10 }} onClick={confirmOrderPacking}

                    >Mark Packed</Button>)}


                </Grid>
            </Grid>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </>
    )
}

export default OrderPackingPage
