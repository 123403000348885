
import React from 'react'
import OrderPackingPage from './order-packging-page/OrderPackingPage';

function App() {
  return (
    <>
      <OrderPackingPage />
    </>
  );
}

export default App;

